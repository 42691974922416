button.evt-btn-icon,
.evt-btn-icon button:disabled {
  background-color: transparent;
  padding: 3px;
  height: auto;
  line-height: 0px;
  font-size: 16px;
  color: #78909C;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: none;
}

.evt-btn-icon button:disabled {
  color: #D6DDE1 !important;
}
.evt-btn-icon:hover {
  color: #1786FC;
}
.evt-btn-icon:focus,
.evt-btn-icon:active {
  border: none;
  outline: none;
}
.evt-modal-confirm .ant-modal-content {
  padding: 32px 24px 64px 24px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
}
.evt-modal-confirm .ant-modal-header {
  padding: 0px;
  border-bottom: none;
}
.evt-modal-confirm .ant-modal-title {
  text-align: center;
  font: normal normal bold 21px/13px Nunito Sans;
  letter-spacing: 0px;
  color: #DC331D;
  margin-bottom: 28px;
}
.evt-modal-confirm .ant-modal-body {
  padding: 0px;
  text-align: center;
  font: normal normal medium 14px/13px Lato;
  letter-spacing: 0px;
  color: #57647B;
  margin-bottom: 48px;
}
.evt-modal-confirm .ant-modal-body p {
  margin-bottom: 0px;
}
.evt-modal-confirm .ant-modal-footer {
  padding: 0px;
  text-align: center;
  border-top: none;
}
.evt-modal-confirm .ant-btn {
  width: 144px;
  text-align: center;
  font: normal normal bold Lato;
  letter-spacing: 0px;
  border-color: #1A90FF;
  color: #1A90FF;
  margin: 0px 12px;
}
.evt-modal-confirm .ant-btn:hover {
  border-color: #40a9ff;
  color: #40a9ff;
}
.evt-modal-confirm .ant-btn.ant-btn-primary {
  color: #FFFFFF;
}
.evt-modal-confirm .anticon.anticon-loading {
  color: #FFFFFF;
}
body {
  background-color: #F5F5FA !important;
}

.contenedor-vacante {
  font-family: "Lato", sans-serif;
  margin-top: 32px;
}

.contenedor-vacante__text {
  font-size: 21px;
  color: #333F48;
}

.contenedor-vacante__text--bold {
  font-weight: bold;
}

.contenedor-principal {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contenedor-encabezado {
  width: 100%;
  height: 90px;
}

.contenedor-inicio {
  height: 608px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 12px #00000033;
  border-radius: 10px;
  opacity: 1;
  margin-top: 24px;
}

/* seccion vista validacion */

.contenedor-validacion {
  width: 100%;
  height: 608px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Lato", sans-serif;
}

/* video */

.contenedor-puesto {
  width: 1208px;
  height: 80px;
}

.contenedor-video {
  height: 680px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 12px #00000033;
  border-radius: 10px;
  opacity: 1;
  margin-top: 24px;

}

@media only screen and (min-width: 1200px) {
  .contenedor-principal {
    height: 100vh;
  }

  .video-sin-participantes {
    height: 315px !important;
  }
}

@media only screen and (min-width: 768) {
  .contenedor-principal {
    height: 100vh;
  }
}

@media only screen and (min-width: 576px) {
  .contenedor-principal {
    height: 105vh;
  }
}

@media only screen and (min-width: 1500px) {
  .contenedor-principal {
    height: 100vh;
  }
}

@media only screen and (max-width: 576px) {
  .contenedor-principal {
    height: 100vh;
  }
}

@media (max-width: 1199px) {
  .video-con-participantes {
    width: 530px !important;
  }
  .video-sin-participantes {
    width: 530px !important;
    height: 315px !important;
  }
  .more-participants {
    width: 530px !important;
  }
  .tarjeta-video {
    width: 530px !important;
  }
  .contenedor-controles-entrevistador {
    width: 240px !important;
    background: #00205B 0% 0% no-repeat padding-box;
    border-bottom-left-radius: 44%;
    border-top-left-radius: 44%;
    border-top-right-radius: 44%;
    border-bottom-right-radius: 44%;
  }
  .inicio-seccion-controles-interviewer {
    width: 55px !important;
  }
  .inicio-seccion-controles-interviewer__text-tiempo {
    font-size: 12px !important;
  }
  .div-iconos-control {
    width: 30px !important;
  }
  .div-iconos-control__imagenes {
    width: 15px !important;
    height: 15px !important;
  }
  .div-iconos-control__imagen-fin-llamada {
    width: 24px !important;
    height: 15px !important;
  }
  .final-seccion-controles-interviewer {
    width: 50px !important;
  }
  .contenedor-nuevos-componentes {
    width: 420px !important;
  }
  .tiempo-restante__texto-concluye {
    font-size: 10px !important;
  }
  .tiempo-restante__restante {
    font-size: 14px !important;
  }
}

@media (max-width: 991px) {
  .video-con-participantes {
    width: 395px !important;
    height: 330px;
  }
  .video-sin-participantes {
    width: 395px !important;
    height: 415px;
  }
  .tarjeta-video {
    width: 395px !important;
  }
  .more-participants {
    width: 395px !important;
  }
  .contenedor-nuevos-componentes {
    width: 420px !important;
  }
  .tiempo-restante__texto-concluye {
    font-size: 8px !important;
  }
  .tiempo-restante__restante {
    font-size: 14px !important;
  }
  .contenedor-nuevos-componentes {
    width: 367px !important;
  }
  .agregar-tiempo {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .video-con-participantes {
    width: 500px !important;
    height: 330px;
  }
  .video-sin-participantes {
    width: 500px !important;
    height: 415px;
  }
  .tarjeta-video {
    width: 500px !important;
  }
  .more-participants {
    width: 500px !important;
  }
  .contenedor-nuevos-componentes {
    width: 420px !important;
  }
  .tiempo-restante__texto-concluye {
    font-size: 10px !important;
  }
  .tiempo-restante__restante {
    font-size: 14px !important;
  }
}

.seccion-video {
  height: 608px;

  
}

.seccion-video > .border-0{
   margin-bottom: 20px;
}

.video-con-participantes {
  width: 720px;
  height: 330px;
  background-color: black;
  margin-top: 2%;
  display: flex;
  align-items: center;
  padding: 0px;
}

.video-sin-participantes {
  width: 720px;
  height: 415px;
  background-color: black;
  margin-top: 2%;
  display: flex;
  align-items: center;
  padding: 0px;
}

.more-participants {
  width: 720px;
  height: 80px;
  background-color: black;
}

.video-individual{
  width: 180px !important;
  height: 80px !important;
  display: inline-block;
}

.sin-video {
  min-height: 25em;
  width: 45em;
}

.etiqueta-grabacion {
  position: absolute;
  z-index: 2;
  text-align: right;
  width: 100%;
  height: 19px;
}

.etiqueta-grabacion__label {
  color: white;
  text-align: right;
  padding-right: 1%;
  font-size: 10px;
  height: 100%;
  background-color: #000000;
  width: 70px;
  float: right;
  padding-top: 2px;
}

.etiqueta-grabacion__icon {
  color: red;
  font-size: 10px;
}


.contenedor-controles-candidatos {
  height: 56px;
  opacity: 1;
  padding-top: -10px;
  z-index: 2;
  background: #00205B 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 44%;
  border-top-left-radius: 44%;
  border-top-right-radius: 44%;
  border-bottom-right-radius: 44%;
}

.contenedor-controles-entrevistador {
  width: 372px;
  height: 56px;
  background: #00205B 0% 0% no-repeat padding-box;
  border-bottom-left-radius: 44%;
  border-top-left-radius: 44%;
  border-top-right-radius: 44%;
  border-bottom-right-radius: 44%;
  opacity: 1;
  padding-top: -10px;
  z-index: 2;
}

.contenedor-nuevos-componentes {
  width: 535px;
  height: 56px;
  background: var(--unnamed-color-00205b) 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
}

.inicio-seccion-controles-interviewer {
  height: 56px;
  border-top-left-radius: 44%;
  border-bottom-left-radius: 44%;
  background: #00205B 0% 0% no-repeat padding-box;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 0px;
  padding-left: 11px;
  width: 76px;
  margin-left: 14px;
}

.inicio-seccion-controles-candidato {
  height: 56px;
  border-top-left-radius: 44%;
  border-bottom-left-radius: 44%;
  background: #00205B 0% 0% no-repeat padding-box;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 0px;
  padding-left: 11px;
  width: 70px;
  margin-left: 14px;
}

.tiempo-restante {
  height: 56px;
  border-top-left-radius: 42%;
  border-bottom-left-radius: 42%;
  line-height: 18px;
  padding-top: 4px;
  padding-right: 5px;
  width: 90px;
  font-family: "Lato", sans-serif;
  color: #00205B;
}

.label-amarillo {
  height: 56px;
  background: #C0D53B 0% 0% no-repeat padding-box;
  padding-top: 4px;
}

.label-rojo {
  height: 56px;
  background: #EF1111 0% 0% no-repeat padding-box;
  padding-top: 4px;
  color: #FFFFFF;
}

.label-azul {
  height: 56px;
  background: #1786FC 0% 0% no-repeat padding-box;
  padding-top: 4px;
}

.tiempo-restante__texto-concluye {
  font-size: 11px;
  white-space: nowrap;
  padding-bottom: 0px;
  margin-bottom: 0px;
  padding-top: 3px;
}

.tiempo-restante__restante {
  font-size: 16px;
  white-space: nowrap;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
  padding-left: 8px;
}

.tarjeta-video {
  width: 720px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}

.agregar-tiempo {
  width: 90px;
  height: 56px;
  border-top-right-radius: 42%;
  border-bottom-right-radius: 42%;
  background: #1786FC 0% 0% no-repeat padding-box;
  padding-right: 0px;
  padding-top: 0px;
  margin-top: 0px;
  line-height: 14px;
  cursor: pointer;
  font-family: "Lato", sans-serif;
}

.inicio-seccion-controles-interviewer__text-tiempo {
  margin-top: 7px;
  font-size: 20px;
  color: #FFFFFF;
  text-align: right;
  font-size: 16px;
}

.final-seccion-controles-interviewer {
  width: 76px;
  height: 56px;
  border-top-right-radius: 44%;
  border-bottom-right-radius: 44%;
  background: #00205B 0% 0% no-repeat padding-box;
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 0px;
}

.final-seccion-controles-candidato {
  width: 60px;
  height: 56px;
  border-top-right-radius: 44%;
  border-bottom-right-radius: 44%;
  background: #00205B 0% 0% no-repeat padding-box;
  align-items: center;
  display: flex;
  justify-content: start;
  padding-left: 5px;
}

.div-iconos-control__imagenes {
  width: 24px;
  height: 24px;
}

.div-iconos-control__imagen-fin-llamada {
  width: 40px;
  height: 24px;
}

.div-iconos-control {
  background: #00205B 0% 0% no-repeat padding-box;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 45px;
}

.div-iconos-control:hover, .final-seccion-controles:hover {
  background: #01266b 0% 0% no-repeat padding-box;
}

.video-OP {
  width: 100%;
  height: auto;
}

.streamcomponent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.streamcomponent .contenedor-stream {
  width: 100%;
  height: 100%;
  border: 1px solid;
  display: flex;
}

.nombre-sin-video {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2f302f;
}

.nombre-participante {
  position: absolute;
  background-color: black;
  height: 18px;
  color: white;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 10px;
}

.nombre-sin-video__circulo {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1F489A;
  border-radius: 50%;
  color: white;
  font-size: 20px;
}

.nombre-sin-video__avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 1px solid;
  background-color: #1F489A;
}

.height-total {
  height: 100%;
  padding: 0px;
}

.height-medio {
  height: 50%;
  padding: 0px;
}

/* fin video */

.externo {
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 480px;
}

.inner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* seccion de controles de tabs */

.secciones {
  width: 100%;
  border-left: 1px solid #00000033;
  padding-right: 16px;
}

.secciones__iconos {
  width: 24px;
  height: 24px;
  filter: invert(50%) sepia(5%) saturate(643%) hue-rotate(161deg) brightness(95%) contrast(91%);
}

.secciones__iconos--active {
  filter: invert(35%) sepia(92%) saturate(1882%) hue-rotate(198deg) brightness(102%) contrast(98%);
}

.tab-secciones {
  display: flex;
  justify-content: center;
}

.header-tab {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 28px;
}

.header-tab__title {
  color: #333F48;
  font-size: 21px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.card-body-tabs {
  height: 420px;
}

/* fin seccion controles de tabs */

/* inicio header videoEntrvista */

.perfil-avatar {
  float: left;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  margin-right: 5px;
  overflow: hidden;
}

@media (min-width: 768px) {
  .perfil-avatar {
    width: 64px;
    height: 64px;
  }
}

.perfil-avatar>img {
  width: 100%;
  height: auto;
}

.perfil-header {
  overflow: hidden;
  line-height: 10px;
  white-space: nowrap;
  text-align: left;
  padding-top: 10px;
  text-overflow: ellipsis;
  font-family: "Lato", sans-serif;
  padding-left: 16px;
}

.perfil-header__name {
  font-size: 20px;
  font-weight: bold;
}

.perfil-header__age {
  font-size: 14px;
  color: #333F48;
}

.perfil-header__email {
  font-size: 14px;
  color: #737C82;
}

.salario {
  padding-top: 10px;
  line-height: 15px;
  border-left: 1px solid #C1C6C8;
  opacity: 1;
  font-family: "Lato", sans-serif;
}

.salario__text {
  font-size: 14px;
  font-weight: bold;
}

/* fin header videoEntrevista */

.boton-volver-ingresar {
  width: 160px;
  height: 32px;
  background: var(--unnamed-color-1786fc) 0% 0% no-repeat padding-box;
  background: #1786FC 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 0px;
  margin-right: 16px;
}

/* inicio seccion estoy listo */

.interrogacion__imagen {
  height: 64px;
  width: 64px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% auto;
  display: block;
  margin: auto;
}

.seccion-informacion__title {
  font-size: 20px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  margin-top: 16px;
  margin-bottom: 0px;
}

.seccion-informacion__mensaje {
  font-size: 16px;
  text-align: center;
  font-family: "Lato", sans-serif;
  margin-top: 16px;
  margin-bottom: 0px;
}

.seccion-informacion__boton {
  width: 160px;
  height: 32px;
  background: var(--unnamed-color-1786fc) 0% 0% no-repeat padding-box;
  background: #1786FC 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 0px;
  font-family: "Lato", sans-serif;
  margin-top: 28px;
}

/* fin seccion estoy listo */

/* seccion de espera de participantes */

.mas-participantes__imagen {
  width: 120px;
  height: 88px;
  display: block;
  margin: auto;
}

.mas-participantes-integrantes {
  width: 300px;
  text-align: center;
  display: block;
  margin: auto;
}

.mas-participantes-integrantes__text {
  font-size: 20px;
  font-weight: bold;
}

/* fin seccion de espera de participantes */

/* seccion de fin de estrevista */

.finalizar {
  font-family: "Lato", sans-serif;
}

.finalizar__imagen {
  width: 120px;
  height: 88px;
  display: block;
  margin: auto;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.finalizar__text {
  font-size: 20px;
  font-weight: bold;
}

.finalizar__boton {
  width: 160px;
  height: 32px;
  background: var(--unnamed-color-1786fc) 0% 0% no-repeat padding-box;
  background: #1786FC 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  color: white;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  border: 0px;
  margin-top: 16px;
}

.finalizar__boton--mright {
  margin-right: 16px;
}

.finalizar__boton:focus {
  border: 0 !important;
  outline: 0px;
}

/* fin seccion de fin de entrevista */

.Absolute-Center {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* componente de documentos */

.header-document {
  font-family: "Lato", sans-serif;
}

.header-document__titulo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 77%;
  margin: auto;
  font-size: 16px;
  font-weight: bold;
}

.header-document__accion {
  font-size: 11px;
  color: #1786FC;
  margin: auto;
}

.seccion-documentos-compartidos {
  height: 120px;
  width: 100%;
  overflow-y: scroll;
  padding-left: 0px;
  padding-right: 12px;
  display: inline-block;
}

.seccion-documentos-compartidos-candidato {
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  padding-left: 0px;
  padding-right: 12px;
  display: inline-block;
}

.documento-compartido {
  width: 100%;
  height: 37px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 0px;
  padding-left: 10px;
  font-family: "Lato", sans-serif;
  padding-top: 5px;
  background-color: transparent;
}

.documento-compartido:hover {
  background-color: #F5F6F8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: transparent !important;
}

.documento-compartido__nombre {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;
  margin: auto;
  padding-left: 0px;
  font-size: 14px;
}

.icono-accion-documento {
  font-size: 13px;
  color: #1786FC;
}

.documento-compartido__avatar {
  float: left;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
  overflow: hidden;
}

.documento-compartido__avatar>img {
  width: 100%;
  height: auto;
}

.documento-compartido__bton-descarga {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: transparent;
  border: 0px;
}

.documento-compartido__bton-descarga:hover {
  background-color: transparent;
}

.contenedor-copiar-clib {
  width: 100%;
  height: 27px;
  text-align: right;
  padding-top: 1%;
}

.contenedor-copiar-clib label {
  background-color: #e9ecef;
  width: 62px;
  text-align: center;
  border-radius: 4px;
  color: black;
  font-size: 14px;
}

.contenedor-informacion-note {
  width: 100%;
  text-align: center;
  margin-top: 5px;
}

.contenedor-informacion-note_text {
  background-color: #e9ecef;
  text-align: center;
  border-radius: 4px;
  color: black;
  font-size: 14px;
  padding: 4px;
}

/* Fin seccion documentos */

/* seccion de notas */

.seccion-notas {
  width: 100%;
  height: 200px;
}

/* fin seccion notas */

/* seccion preguntas */

.tipo-pregunta:hover {
  background: #E5F4FF 0% 0% no-repeat padding-box;
}

.list-group__tipo-pregunta-seleccionado {
  background: #E5F4FF 0% 0% no-repeat padding-box;
}

.contenedor-preguntas {
  width: 100%;
  height: 225px;
  overflow-y: scroll;
  /*Estilos estándar experimentales (Principalmente Firefox)*/
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.contenedor-preguntas-profunda {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
  /*Estilos estándar experimentales (Principalmente Firefox)*/
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
}

.pregunta-profunda {
  margin-top: 7px;
  margin-bottom: 55px;
  height: auto;
  font-size: 12px;
  font-weight: bold;
  color: #333F48;
}

.paginacion-pregunta {
  text-align: right;
  background-color: transparent;
}

.paginacion-pregunta__input {
  top: 641px;
  left: 1152px;
  width: 48px;
  height: 22px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B4BABC;
  border-radius: 4px;
  opacity: 1;
}

.pregunta-competencia {
  font-family: "Lato", sans-serif;
}

.pregunta-competencia__header {
  font-size: 12px;
  font-weight: bold;
}

.pregunta-competencia__text {
  font-size: 12px;
  color: #737C82;
}

.pregunta-competencia__text--blue {
  font-size: 10px;
  color: #1786FC;
}

/* fin seccion preguntas */

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

.contenedor-preguntas::-webkit-scrollbar, .seccion-documentos-compartidos::-webkit-scrollbar, .contenedor-preguntas-profunda::-webkit-scrollbar, .seccion-documentos-compartidos-candidato::-webkit-scrollbar {
  -webkit-appearance: none;
}

.contenedor-preguntas::-webkit-scrollbar:vertical, .seccion-documentos-compartidos::-webkit-scrollbar:vertical, .contenedor-preguntas-profunda::-webkit-scrollbar:vertical, .seccion-documentos-compartidos-candidato::-webkit-scrollbar:vertical {
  width: 10px;
}

.contenedor-preguntas::-webkit-scrollbar-button:increment, .contenedor-preguntas::-webkit-scrollbar-button, .seccion-documentos-compartidos::-webkit-scrollbar-button:increment, .seccion-documentos-compartidos::-webkit-scrollbar-button, .contenedor-preguntas-profunda::-webkit-scrollbar-button:increment, .contenedor-preguntas-profunda::-webkit-scrollbar-button, .seccion-documentos-compartidos-candidato::-webkit-scrollbar-button:increment, .seccion-documentos-compartidos-candidato::-webkit-scrollbar-button {
  display: none;
}

.contenedor-preguntas::-webkit-scrollbar-thumb, .seccion-documentos-compartidos::-webkit-scrollbar-thumb, .contenedor-preguntas-profunda::-webkit-scrollbar-thumb, .seccion-documentos-compartidos-candidato::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 3px solid #f1f2f3;
}

.contenedor-preguntas::-webkit-scrollbar-track, .seccion-documentos-compartidos::-webkit-scrollbar-track, .contenedor-preguntas-profunda::-webkit-scrollbar-track, .seccion-documentos-compartidos-candidato::-webkit-scrollbar-track {
  border-radius: 10px;
}

.start {
  word-spacing: 5px;
}

/* seccion datos generales */

.header-berchmark {
  font-family: "Lato", sans-serif;
}

.header-berchmark__afinidad {
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000A6;
}

.header-berchmark__porcentaje {
  font-size: 16px;
  letter-spacing: 0px;
  color: #0C7BEA;
}

.identificadores-berchmark {
  margin-top: 47px;
  font-family: "Lato", sans-serif;
}

.identificadores-berchmark__text {
  font-size: 12px;
  color: #A7A7A7;
  white-space: nowrap;
}

.identificadores-berchmark__icon-perfil {
  color: #0C7BEA;
}

.identificadores-berchmark__icon-evaluates {
  color: #A07EF8;
}

.header-berchmark__oportunidad {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #17191A;
  opacity: 1;
  margin-top: 41px;
}

.competence {
  font-family: "Lato", sans-serif;
}

.competence__name {
  font-style: italic;
  font-size: 11px;
  color: #595959;
}

.competence__perfil {
  font-size: 16px;
  color: #0C7BEA;
}

.competence__evaluates {
  font-size: 16px;
  color: #A07EF8;
}

.competence__title-rango {
  font-size: 8px;
  color: #52C41A;
  float: left;
}

.competence__text-rango {
  font-size: 10px;
  color: #2C2929;
  float: left;
  margin-left: 5px;
  margin-top: 4%;
}

/* fin seccion datos generales */

/* chat */

.seccion-chat {
  height: auto;
  width: 450px;
}

.alert-chat {
  font-size: 10px;
  color: #808080;
  margin-bottom: 0px;
  width: 216px;
  font-family: "Lato", sans-serif;
}

.alert-send {
  background-color: #F5F6F8;
  border: 0;
}

.alert-reserved {
  background-color: #E6F7FF;
  border: 0;
}

.alert-chat__position-right {
  text-align: right;
  margin-bottom: 1px !important;
  color: #000000;
  font-family: "Lato", sans-serif;
}

.font-medium-chat {
  font-size: 12px;
  color: #000000;
  font-family: "Lato", sans-serif;
}

.font-large-chat {
  font-size: 14px;
  background-color: #F5F5FA;
  color: #000000;
  font-family: "Lato", sans-serif;
}

.tab-chats {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.tab-chats__all-chat {
  font-size: 24px !important
}

.input-chat {
  background-color: #F5F5FA;
  color: black;
}

.input-chat__boton {
  border-left-color: transparent;
  border-top-color: #d3d3d3;
  border-bottom-color: #d3d3d3;
  border-right-color: #d3d3d3;
}

.input-chat__boton--right {
  border-right-color: transparent;
  background-color: #F5F5FA;
}

.MessageList {
  height: 300px;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 16px;
  /*Estilos estándar experimentales (Principalmente Firefox)*/
  scrollbar-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, 0);
  scrollbar-width: thin;
  font-family: "Lato", sans-serif;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lato", sans-serif;
}

.chat__avatar {
  float: left;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
  overflow: hidden;
}

.chat__avatar>img {
  width: 100%;
  height: auto;
}

.MessageList::-webkit-scrollbar {
  -webkit-appearance: none;
}

.MessageList::-webkit-scrollbar:vertical {
  width: 10px;
}

.MessageList::-webkit-scrollbar-button:increment, .MessageList::-webkit-scrollbar-button {
  display: none;
}

.MessageList::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 3px solid #f1f2f3;
}

.MessageList::-webkit-scrollbar-track {
  border-radius: 10px;
}

.tab-chats>a {
  padding-top: 0px;
  padding-bottom: 0px;
}

.chat-file {
  width: 242px;
  border: 1px solid lightgrey;
  border-radius: .25rem;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.margin-chat-l {
  margin-left: 55px !important;
}

.margin-chat-r {
  margin-right: 55px !important;
}

/* fin seccion chat */

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.cardq {
  flex: 0 0 auto;
  border-right: solid 2px #a7b0b6;
}

.label-contador {
  padding-right: 25px;
  text-align: right;
}

.tab-chats::-webkit-scrollbar, .seccion-documentos-compartidos::-webkit-scrollbar {
  -webkit-appearance: none;
}

.tab-chats::-webkit-scrollbar:vertical, .seccion-documentos-compartidos::-webkit-scrollbar:vertical {
  width: 10px;
}

.tab-chats::-webkit-scrollbar-button:increment, .tab-chats::-webkit-scrollbar-button, .seccion-documentos-compartidos::-webkit-scrollbar-button:increment, .seccion-documentos-compartidos::-webkit-scrollbar-button {
  display: none;
}

.seccion-documentos-compartidos::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.tab-chats::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
  border: 1px solid #ffffff;
}

.tab-chats::-webkit-scrollbar-track, .seccion-documentos-compartidos::-webkit-scrollbar-track {
  border-radius: 10px;
}

.tab-chats::-webkit-scrollbar:horizontal{
  height: 8px;
}

.save-process-indicator__icon {
  font-size: 20px;
  color: #1786FC !important;
  margin-left: 12px;
  margin-right: 6px;
}
.save-process-indicator__text {
  text-align: left;
  font: normal normal bold 10px/12px Lato;
  letter-spacing: 0px;
  color: #1786FC;
  font-size: 10px;
}
.compatibility-interview {
  width: 100%;
  max-width: 522px;
  min-width: 522px;
  margin: auto;
}
.compatibility-interview__image {
  text-align: center;
  margin-bottom: 42px;
}
.compatibility-interview__image img {
  width: 391px;
  height: auto;
}
.compatibility-interview__text {
  text-align: center;
  font: normal normal bold 15px/20px Lato;
  letter-spacing: 0px;
}
.compatibility-interview__text p {
  margin-bottom: 2px;
}
.compatibility-interview__text a {
  text-decoration: underline;
  color: #1786FC;
}
.compatibility-interview__text a:hover {
  text-decoration: underline;
}
.text-brive {
    font: oblique 120% sans-serif bold;
    letter-spacing: 0px;
    color: #10069F;
    opacity: 1;
    font-weight: bold;
}
.contenedor-captcha{
    justify-content:center ;
    align-items: center;
    margin-bottom: 15px;
    display: flex;
}
.size-logo{
width: 357px;
height: 55px;
}
.estilo-boton-contra{
    border-left-color: transparent;
    border-top-color: #d3d3d3;
    border-bottom-color: #d3d3d3;
    border-right-color: #d3d3d3;
    background-color: #ffffff;
    
   }
   .estilo-icono-contra{
    color: #000000;
    size: 9px;
    
   }
   .ocultar{
       display: none;
   }
   .estiloAlert{
       text-align: center;
       height: 65px;
   }
   .label-advertencia{
    color:#ff0000;
    font-size: 14px;
}
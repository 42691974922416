.modal-candidato{
    width: 100%;
    height: 350px;
}

.modal-footer-candidato__boton-cancelar{
    width: 120px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #1786FC;
    border-radius: 4px;
    opacity: 1;
    color: #1786FC;
}

.modal-footer-candidato__boton-aceptar{
    width: 120px;
    height: 32px;
    background: #1786FC 0% 0% no-repeat padding-box;
    border: 1px solid #1786FC;
    border-radius: 4px;
    opacity: 1;
    color: white;
}

.modal-candidato__text{
    font-size: 12px;
    font-family: "Lato", sans-serif;
}

.modal-candidato__stars{
    text-align: center;
}

.modal-candidato__text-area{
    height: 112px;
    width: 440px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    opacity: 1;
}

.titulo-modal-candidato{
    font-size: 16px;
    font-weight: bold;
    font-family: "Lato", sans-serif;
}

.texto--bold{
    font-weight: bold;
}
